.version {
	font-weight: bold;
	margin-right: 1.5pt;
}

.releaseDate {
	color: #999999;
	font-size: smaller;
}

.error {
	color: red;
}
