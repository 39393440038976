.userInfo {
	text-align: right;
	a {
		color: black;
		font-size: 1.3em;
	}
}

#logout-icon {
	margin-top: 6px
}

#home-icon {
	margin: 4px 1px;
}

#changePassword-icon {
	margin: 3px 1px 0px;
	font-size: 1.5em;
}
