@import '../node_modules/react-toastify/dist/ReactToastify.css';

body {
	margin: 0;
}

#root {
	min-height: 100%;
	display: grid;
	grid-template-rows: auto auto 1fr auto;
}

#root footer {
	padding: 1em;
}

#root .header {
	background-color: #444444;
	color: #dfdfdf;
	padding: 0.5em;
}
