.portal-header {
	background-color: #e1eaf3;
	padding: 0.5em;
	box-shadow: 0px 0px 7px #000000;
	margin-bottom: 1em;

	.header-first-row {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		height: 5em;

		img {
			height: 3.2em;
		}

		.logo-mint {
			filter: invert(60%) sepia(59%) saturate(398%) hue-rotate(116deg) brightness(89%) contrast(94%);
		}

		.logo-coconut {
			filter: invert(100%);
		}

		.logo-blueberry {
			filter: invert(74%) sepia(26%) saturate(2995%) hue-rotate(160deg) brightness(83%) contrast(86%);
		}

		.logo-corn{
			filter: invert(99%) sepia(33%) saturate(3108%) hue-rotate(325deg) brightness(104%) contrast(103%);
		}

		.logo-fig {
			filter: invert(18%) sepia(74%) saturate(1417%) hue-rotate(181deg) brightness(100%) contrast(94%);
		}

		.logo-rhubarb {
			filter: invert(57%) sepia(40%) saturate(573%) hue-rotate(303deg) brightness(89%) contrast(108%);
		}

		.logo-sage {
			filter: invert(39%) sepia(30%) saturate(959%) hue-rotate(130deg) brightness(94%) contrast(82%);
		}

		.logo-pepper {
			filter: invert(0%)
		}
	}

	.header-left {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
	}

	.header-title{
		color: #369;
		font-weight: bold;
	}
}
