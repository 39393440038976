$font-size-base: 1rem;
$blue: #2667ae;
$body-bg: #f8f7fc;

@import '~bootstrap/scss/bootstrap-reboot.scss';
@import '~bootstrap/scss/bootstrap.scss';
@import '~@tunz/ifs-react-lib/dist/index.css';
$table-bg: #ffffff;
$card-bg: #ffffff;

.card {
	--bs-card-bg: #fff;
}

.table {
	--bs-table-bg: #fff;
}

.modal-content {
	--bs-modal-bg: #fff;
}

html {
	font-size: 12px;
	height: 100%;
}

body {
	height: 100%;
}

.w-65 {
	width: 65%;
}

.w-35 {
	width: 35%;
}
