$dark: #369;
$light: #e1eaf3;
$gradient-top: #d4e0ec;
$gradient-bottom: #b0c5da;

.portal-menu-item {
	display: block;
	padding: 4px;
	margin: 2px 1px 0 1px;
	border-radius: 5px;
	border: 1px solid #a2b2c3;
	text-decoration: none;
	color: $dark;
	background: linear-gradient(to bottom, $gradient-top, $gradient-bottom);
	font-weight: bold;
}

.portal-menu-item:hover {
	color: $light;
	background: linear-gradient(to bottom, darken($gradient-top, 25), darken($gradient-bottom, 25));
	text-decoration: none;
	cursor: pointer;
}

.portal-menu-item-selected {
	color: $light;
	background: linear-gradient(to bottom, darken($gradient-top, 35), darken($gradient-bottom, 35));
}
