.brand-area {
	text-align: center;
}

.brand-img {
	height: 75px;
}

.brand-title {
	font-size: 1.2em;
	font-weight: bold;
}

.brand-sub-title {
	font-style: italic;
	font-weight: 300;
}

#lostPasswordForm{
	min-height: 150px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

#lostPasswordButtons, #unsuspendUserButtons{
	display: flex;
	justify-content: flex-end;
	gap: 10px;
}
